@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.scss";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';


@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$ion-text-padding-top: 10px;
$ion-text-padding-bottom: 10px;
$ion-text-padding-left-right: 10px;

$ion-button-margin-top: 10px;
$ion-button-margin-bottom: 10px;
$ion-button-margin-left-right: 20px;

$input-border-size: 2px;
$input-border-color: var(--ion-color-primary);
$input-border-color-error: var(--ion-color-secondary);


:root {
	--ion-color-primary: #575746;
	--ion-color-primary-rgb: 87,87,70;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #4d4d3e;
	--ion-color-primary-tint: #e9e9e9;

	--ion-color-secondary: #D80000;
	--ion-color-secondary-rgb: 216,0,0;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #be0000;
	--ion-color-secondary-tint: #dc1a1a;

	--ion-color-tertiary: #D83A00;
	--ion-color-tertiary-rgb: 216,58,0;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #be3300;
	--ion-color-tertiary-tint: #dc4e1a;

	--ion-color-success: #008000;
	--ion-color-success-rgb: 0,128,0;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #007100;
	--ion-color-success-tint: #1a8d1a;

	--ion-color-warning: #ffff00;
	--ion-color-warning-rgb: 255,255,0;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0e000;
	--ion-color-warning-tint: #ffff1a;

	--ion-color-danger: #ff0000;
	--ion-color-danger-rgb: 255,0,0;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #e00000;
	--ion-color-danger-tint: #ff1a1a;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

    --ion-item-background: var(--ion-color-primary-tint,#fff);

    --ion-card-background: #fff;

}

* {
    --text-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;    

    // $input-border-size: 2px;
    // var($input-border-color): black;


    
    --input-padding-top: 10px;
    --input-padding-bottom: 10px;
    --input-padding-left-right: 5px;
    --input-margin-top: 10px;
    --input-margin-bottom: 5px;
    --input-margin-left-right: 0px;
    --input-disabled-background-color:  #fdf2f2;

    --ion-label-font-size: 22px;

    --ion-note-text-error-color: white;
    --ion-note-text-error-background-color: red;
    --ion-note-text-helper-color: white;
    --ion-note-text-helper-background-color: green;
    --ion-note-padding: 5px;
}


ion-content{
    --background: var(--ion-color-primary-tint)
}

ion-label {
    font-size: var(--ion-label-font-size) !important;
    color : var(--ion-label-font-color) !important;
}

ion-item{
    fa-icon{
        min-width: 18px;
        text-align: center;
    }
}

ion-item:has(ion-input,ion-select,ion-textarea,ion-toggle,ion-checkbox){
    ion-label{
        font-weight: bold;
    }
}

ion-input, ion-select, ion-textarea {
    border: $input-border-size solid $input-border-color;
    margin: var(--input-margin-top)  var(--input-margin-left-right) 0  var(--input-margin-left-right) !important;
    --padding-top: var(--input-padding-top) !important;
    --padding-start: var(--input-padding-left-right) !important;
    --padding-bottom: var(--input-padding-bottom) !important;
    --padding-end: var(--input-padding-left-right) !important;
    background: #fff !important;
    --background: #fff !important;
    border-radius: 10px;
    min-height: 50px;
}


ion-col.col-center-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
}

// ion-button {  
//     color: #fff !important;
// }

ion-text {
    margin: 10px 20px;
    display: block;
}

signature-pad {
    margin: var(--input-margin-top)  var(--input-margin-left-right) var(--input-margin-bottom)  var(--input-margin-left-right) !important;
}

ion-input[ng-reflect-readonly=true]{
    --background: var(--input-disabled-background-color);    
}

ion-input.ng-invalid{
    border-color: $input-border-color-error;
}

ion-note[slot=error] {
    color: var(--ion-note-text-error-color) !important;
    background-color: var(--ion-note-text-error-background-color) !important;
    padding: var(--ion-note-padding) !important;
    display: block;
}

ion-note[slot=helper] {
    color: var(--ion-note-text-helper-color) !important;
    background-color: var(--ion-note-text-helper-background-color) !important;
    padding: var(--ion-note-padding) !important;
    display: block;
}

app-address {
    width: 100% !important;
}

// ion-split-pane {
//     --side-width: 200px;
//     --side-max-width: 200px;
// }

ion-toast.toast-error {
    --background: red;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #fff;
}


ion-alert {
    --min-width: 50% !important;
    --max-width: 80% !important;

    //--min-height: 50% !important;
    --max-height: 80% !important;


    div.alert-wrapper {
        overflow-y: auto;

        .alert-message {
            padding-top: 20px !important;
        }
    }


    div.alert-head:has(.alert-title){
        // min-width: 50%;
        // max-width: 80%;
        background-color: var(--ion-color-primary);
        h2{
            color: var(--ion-color-primary-contrast) !important;
        }
    }

    div.alert-radio-group{
        display: inline-table;
    }

    // div.alert-button-group {

    //     button {
    //         background-color: var(--ion-color-base) !important;
    //         color: var(--ion-color-contrast) !important;
    //     }

    //     button.alert-button-role-cancel {
    //         border-color: var(--ion-color-base) !important;
    //     }
    // }
}


ion-modal{

    --border-radius: 16px;
    --box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;


    ion-content{
        --background: white;
    }
    
    ion-toolbar.toolbar-footer{
        --background: var(--ion-color-primary-tint);
    }
 

    ion-toolbar {
        --background: var(--ion-color-primary);
        --color: var(--ion-color-primary-contrast);

        ion-title{

            color: white !important;

        }
      
        // ion-buttons {
        //     ion-button::part(native) {  
        //         color: red !important;
        //     }        
        // }
    }
}
ion-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
    
}


ion-popover {
    --width: auto;
    
    app-popover-discount {

        ion-content {




        }

    }
    // div.popover-discount{
    //     display: flex !important;
    //     flex-direction: row !important;
    // }
}

ion-grid {
    // --ion-grid-padding: 20px;
  
    // --ion-grid-padding-xs: 20px;
    // --ion-grid-padding-sm: 20px;
    // --ion-grid-padding-md: 20px;
    // --ion-grid-padding-lg: 20px;
    // --ion-grid-padding-xl: 20px;
  
    // --ion-grid-column-padding: 30px;
  
    // --ion-grid-column-padding-xs: 30px;
    // --ion-grid-column-padding-sm: 30px;
    // --ion-grid-column-padding-md: 30px;
    // --ion-grid-column-padding-lg: 30px;
    // --ion-grid-column-padding-xl: 30px;

    --ion-grid-padding: 5px;
  
    --ion-grid-padding-xs: 5px;
    --ion-grid-padding-sm: 5px;
    --ion-grid-padding-md: 5px;
    --ion-grid-padding-lg: 5px;
    --ion-grid-padding-xl: 5px;
  
    --ion-grid-column-padding: 5px;
  
    --ion-grid-column-padding-xs: 5px;
    --ion-grid-column-padding-sm: 5px;
    --ion-grid-column-padding-md: 5px;
    --ion-grid-column-padding-lg: 5px;
    --ion-grid-column-padding-xl: 5px;

    
    ion-col.align-bottom {
        display: flex;
        align-items: flex-end;

        ion-button {
            margin-bottom: 0;
        }

        &.end {
            justify-content : flex-end;
        }
    }

}

// ion-grid {


//     padding: 0;

//     ion-row {

//         ion-col {
//             padding: 0;
//             // display: flex;
//             // align-items: center;

//         }

//     }

//     ion-row.input-qty {

//         // penultima colonna
//         ion-col:nth-last-child(2) {
//             max-width: 200px;
//         }

//         // ultima colonna
//         ion-col:last-of-type {             

//             max-width: 200px;

//             div {
//                 height: 100%;
//                 width: 100%;
//                 display: flex;
//                 align-items: center;
//                 justify-content: flex-end;
//             }
            
//         }

        
//     }


// }



quotation-product {
    border: var($input-border-size) solid var(var($input-border-color));
    display: block;
    margin-bottom: 5px;
}

quotation-product-accessory {
    border: var($input-border-size) solid var(var($input-border-color));
    display: block;
    margin-bottom: 5px;
}


ngx-datatable {
    margin: 15px !important;
}

// tabelle
ngx-datatable {
    div {
        datatable-header{
            height: 45px !important;
        }
    }
}

.ngx-datatable-row-higlight {
    border-color: red !important;
    border-width: 2px;
    border-style: solid;
}

.datatable-body-cell-label {
    ion-img {
        max-width: 150px !important;
        height: 150px !important;
    }
}

div.datatable-row-center {
    datatable-body-cell:last-of-type {
        div.datatable-body-cell-label{
            display: flex;
            justify-content: flex-end;
        }
    }
}

form {
    ion-button {
        // margin: $ion-button-margin-top $ion-button-margin-bottom $ion-button-margin-top $ion-button-margin-left-right;
    }

    

    
}

ion-footer.quotation-footer{

    // margin: 10px 20px;

    div.total {


        font-size: 25px;
        font-weight: bold;

        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);

        text-align: end;


        padding: $ion-text-padding-top $ion-text-padding-left-right;
                  
     
        
    }

    ion-button {
        float:right;
    }
}


ion-col.ion-col-numpad{
    display: flex;
    align-items: flex-end;

    ion-button {
        margin: 0;
    }
}

// MY CLASSES
.my-max-height{
    height: 100%;
}

.my-padding-10{
    padding: 10px
}

.my-padding-bottom-10{
    padding-bottom: 10px
}


ion-accordion-group.white-item-icon{
    ion-accordion {
        .ion-accordion-toggle-icon {
            color: white;
        }
    }
}


ion-accordion-group{
    ion-accordion {

        ion-item.accordion-item {
            --background: var(--ion-color-primary);
            --color: white;
           
        }

        // border: 2px solid var(--ion-color-primary);
        border-radius: 20px;
    }

    ion-accordion::part(content){
        background-color: var(--ion-color-primary-tint);
    }
}

// HEADER TITOLO
ion-header:has(ion-toolbar){
    
    box-shadow: none !important;

    ion-title{
        padding-top: 15px;
        padding-bottom: 15px;

        color: var(--ion-color-primary);

        font-size: 24px; 
        font-weight: bold;
    }
}

ion-searchbar{
    --border-radius:10px !important;
    padding-inline: 15px !important;
}